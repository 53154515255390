import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { useConditionality } from "@unitelife/conditionality";
import type { SurveyDecisionTreeNode } from "types";
import { SurveyAnswers } from "../surveyAnswers";
import { SurveyTestimony } from "../surveyTestimony";
import { useLocation } from 'react-router-dom';

export type SurveyNodeProps = {
  node: SurveyDecisionTreeNode;
  answer?: string;
  previousResponse: string;
  showBackButton: boolean;
  onAnswerChange: (answer: string, path?: string) => void;
  onNextClick: () => void;
  onBackClick: () => void;
};

export const SurveyNode = ({
  node,
  answer,
  previousResponse,
  showBackButton,
  onAnswerChange,
  onNextClick,
  onBackClick,
}: SurveyNodeProps) => {
  const { question_key, question_type, answer_keys } = node.question;
  const location = useLocation();
  const surveyType = location.pathname.split('/survey/')[1];
  const { t } = useTranslation();

  useConditionality(node, onNextClick);

  const handleBackClick = () => {
    if (showBackButton) {
      onBackClick();
    }
  };

  return (
    <div className="survey-node">
      <p className={`survey-node-text`}>
        {t(`survey.question.${surveyType}.${question_key}`)}?
      </p>
      {question_type === "TESTIMONY" ? (
        <SurveyTestimony answer={answer} onAnswerChange={onAnswerChange} />
      ) : (
        <SurveyAnswers
          answer={answer}
          previousResponse={previousResponse}
          questionKey={question_key}
          questionType={question_type}
          answerKeys={answer_keys}
          onAnswerClick={onAnswerChange}
        />
      )}
      <div className="control-row">
        <div
          className={`control-button back ${!showBackButton && "disabled"}`}
          onClick={handleBackClick}
        >
          <FontAwesomeIcon icon={faArrowLeft} size="sm" />
          {t("survey.controls.goBack")}
        </div>
        <div
          className={`control-button continue ${
            !answer &&
            !previousResponse &&
            node.question.question_type !== "TESTIMONY" &&
            "disabled"
          }`}
          onClick={onNextClick}
        >
          {t("survey.controls.continue")}
          <FontAwesomeIcon icon={faArrowRight} size="sm" />
        </div>
      </div>
    </div>
  );
};

export default SurveyNode;

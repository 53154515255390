import { useState } from "react";
import { api } from "../../../api";
import { StringKeyedObject } from "../../../types";
import { SurveyDto } from "../../../types/survey";
import { CreateSurveyResponseDto } from "../../../types/surveyResponse";
import { useInteractionTrackingContext } from "../interactionTracking/interactionTrackingContext";

export type SurveyAnswersHookResult = {
  answers: StringKeyedObject;
  createAnswer: (questionUuid: string, response: string) => void;
  resetAnswers: () => void;
};

export const useSurveyAnswers = (
  config: SurveyDto
): SurveyAnswersHookResult => {
  const [surveyAnswers, setSurveyAnswers] = useState<StringKeyedObject>({});
  const { interactions } = useInteractionTrackingContext();

  const createAnswer = async (questionUuid: string, res: string) => {
    setSurveyAnswers({
      ...surveyAnswers,
      [questionUuid]: res,
    });

    saveSurveyAnswer(questionUuid, res);
  };

  const saveSurveyAnswer = async (questionUuid: string, response: string) => {
    if (!response) return;

    interactions.createEvent("response", config.survey_type, {
      question_uuid: questionUuid,
      response,
    });

    const result = await api.post<CreateSurveyResponseDto, any>("/response", {
      seeker_uuid: config.seeker.uuid,
      survey_type: config.survey_type,
      question_uuid: questionUuid,
      response,
    });
    if (!result.ok) {
      throw new Error();
    }
  };

  const resetAnswers = () => {
    setSurveyAnswers({});
  };

  return {
    answers: surveyAnswers,
    createAnswer,
    resetAnswers,
  };
};

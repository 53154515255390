import { UserMedia } from "@unitelife/core";
import { rootApi } from "api";
import { CreateUserMediaDto } from "./types";

export const createUserMedia = async (body: CreateUserMediaDto) => {
  return rootApi.post<CreateUserMediaDto, UserMedia>(
    "/cloudflare/create-media",
    body
  );
};

import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { useSurveyContext } from "../../hooks/context/survey/surveyContext";
import { Background } from "@unitelife/core";

type PageProps = {
  /** The page title */
  title: string;
  /** The className of the page, used to add custom styles */
  className: string;
  children?: ReactNode;
};

const Page = ({ title, className, children }: PageProps) => {
  const { surveyConfig } = useSurveyContext();
  const { i18n, t } = useTranslation();

  const pageTitle = t(title);
  if (i18n.isInitialized) {
    document.title = pageTitle;
  }

  return (
    <div className={`page ${className}`}>
      <Background />
      <div className="logo">
        {!!surveyConfig?.partner && (
          <img
            src={surveyConfig.partner.branding_package.logo_url}
            alt="logo"
          />
        )}
      </div>
      <div className="page-card">
        {i18n.isInitialized && <h1 className="page-title">{pageTitle}</h1>}
        {children}
      </div>
    </div>
  );
};
export default Page;

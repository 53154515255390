import { SurveyAnswersHookResult } from "./useSurveyAnswers";
import { SurveyEventsHookResult } from "./useSurveyEvents";
import { SurveyNavigationHookResult } from "./useSurveyNavigation";
import { SurveyProgressHookResult } from "./useSurveyProgress";
import { SurveyResultsHookResult } from "./useSurveyResults";

export type SurveyControlsHookResult = {
  onNextClick: (questionUuid: string, answer: string, path?: string) => void;
  onBackClick: () => void;
  onResetClick: () => void;
  onSubmitClick: () => void;
};

export const useSurveyControls = (
  progress: SurveyProgressHookResult,
  navigation: SurveyNavigationHookResult,
  answers: SurveyAnswersHookResult,
  events: SurveyEventsHookResult,
  results: SurveyResultsHookResult
): SurveyControlsHookResult => {
  const onNextClick = (questionUuid: string, answer: string, path?: string) => {
    answers.createAnswer(questionUuid, answer);
    progress.incrementProgress(navigation.currentNode.path, path ?? answer);
    navigation.moveNext(path ?? answer);
  };

  const onBackClick = () => {
    progress.decrementProgress(navigation.currentNode!.path);
    navigation.moveBack();
  };

  const onResetClick = () => {
    answers.resetAnswers();
    navigation.resetControls();
    progress.resetProgress();
  };

  const onSubmitClick = () => {
    events.registerSurveyCompleted();
    results.getSurveyResults(navigation.surveyPath!);
  };

  return {
    onNextClick,
    onBackClick,
    onResetClick,
    onSubmitClick,
  };
};

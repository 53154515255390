import { useState } from "react";

export type SurveyPathHookResult = {
  currentPathIndex: number;
  resetSurveyPath: () => void;
  surveyPath: string[];
  updateSurveyPath: (path: string, updateIndex: boolean) => void;
  getLastSurveyPath: () => string;
};

export const useSurveyPath = (): SurveyPathHookResult => {
  const [currentPathIndex, setCurrentPathIndex] = useState(0);
  const [surveyPath, setSurveyPath] = useState<string[]>([]);

  const updateSurveyPath = (path: string, updateIndex: boolean) => {
    surveyPath[currentPathIndex] = path;
    setSurveyPath([...surveyPath]);
    if (updateIndex) {
      setCurrentPathIndex(currentPathIndex + 1);
    }
  };

  const getLastSurveyPath = () => {
    let path = surveyPath[currentPathIndex - 1];
    setCurrentPathIndex(currentPathIndex - 1);
    return path;
  };

  const resetSurveyPath = () => {
    setCurrentPathIndex(0);
    setSurveyPath([]);
  };

  return {
    currentPathIndex,
    resetSurveyPath,
    surveyPath,
    updateSurveyPath,
    getLastSurveyPath,
  };
};

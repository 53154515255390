import { TestimonyUpload } from "@unitelife/cloudflare/components/testimonyUpload";
import { useSurveyContext } from "hooks/context/survey/surveyContext";
import { useTranslation } from "react-i18next";

export type SurveyTestimonyProps = {
  answer?: string;
  onAnswerChange: (testimony: string) => void;
};

export const SurveyTestimony = ({
  answer,
  onAnswerChange,
}: SurveyTestimonyProps) => {
  const { surveyConfig } = useSurveyContext();
  const { seeker } = surveyConfig;
  const { t } = useTranslation();

  return (
    <div className="survey-testimony">
      <textarea
        value={answer}
        onChange={(e) => onAnswerChange(e.target.value)}
        placeholder={t("survey.testimony.placeholder")}
        maxLength={1500}
      />
      <p className="remaining-label">
        {1500 - (answer?.length ?? 0)}/{t("survey.testimony.remaining")}
      </p>
      <div className="media-upload-row">
        <TestimonyUpload
          seekerUuid={seeker.uuid}
          uploadKey={seeker.user.name}
          type="profile-photo"
        />

        <TestimonyUpload
          seekerUuid={seeker.uuid}
          uploadKey={seeker.user.name}
          type="testimony"
        />
      </div>
    </div>
  );
};

import { useEffect, useState } from "react";
import { SurveyDecisionTreeNode, SurveyDto } from "../../../types/survey";

const getUniqueQuestionLength = (nodes: SurveyDecisionTreeNode[]) => {
  return Object.keys(
    nodes.reduce((res, node) => ({ ...res, [node.uuid]: node }), {})
  ).length;
};

export type SurveyProgressHookResult = {
  progress: number;
  incrementProgress: (questionPath: string, answer: string) => void;
  decrementProgress: (questionPath: string) => void;
  resetProgress: () => void;
};

export const useSurveyProgress = ({
  tree,
}: SurveyDto): SurveyProgressHookResult => {
  const [uniqueQuestionLength, setUniqueQuestionLength] = useState(0);
  const [questionsCompleted, setQuestionsCompleted] = useState(0);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (tree.nodes?.length > 0) {
      setUniqueQuestionLength(getUniqueQuestionLength(tree.nodes));
    }
  }, [tree.nodes]);

  const getQuestionValue = (questionPath: string, answer: string) => {
    return getUniqueQuestionLength(
      tree.nodes.filter(
        ({ path }) =>
          path.startsWith(questionPath) &&
          !path.startsWith(`${questionPath}.${answer}`)
      )
    );
  };

  const incrementProgress = (questionPath: string, answer: string) => {
    answer = answer.toLowerCase();
    let questionValue = getQuestionValue(questionPath, answer);

    updateProgress(questionsCompleted + questionValue);
  };

  /**
   *
   * @param {string} questionPath The current question's path
   */
  const decrementProgress = (questionPath: string) => {
    if (questionPath.includes(".")) {
      let split = questionPath.split(".");
      let prevPath = split.slice(0, -1).join(".");
      let answer = split[split.length - 1];
      let prevQuestionValue = getQuestionValue(prevPath, answer);
      updateProgress(questionsCompleted - prevQuestionValue);
    } else {
      updateProgress(questionsCompleted - 1);
    }
  };

  const updateProgress = (newCompleted: number) => {
    setQuestionsCompleted(newCompleted);
    setProgress(newCompleted / uniqueQuestionLength);
  };

  const resetProgress = () => {
    setProgress(0);
    setQuestionsCompleted(0);
  };

  return {
    progress,
    incrementProgress,
    decrementProgress,
    resetProgress,
  };
};

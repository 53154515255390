export type SurveyProgressBarProps = {
  /** The progress amount as a decimal between 0 and 1 */
  progress: number;
};

export const SurveyProgressBar = ({ progress }: SurveyProgressBarProps) => {
  const progressWidth = progress * 100;
  return (
    <div className="survey-progress-bar">
      <div className="progress" style={{ width: `${progressWidth}%` }} />
    </div>
  );
};

import { useEffect } from "react";
import { SurveyDecisionTreeNode } from "../../../types";
import checkConditionality, {
  Conditionality,
  Operand,
} from "../conditionality";
import { useSurveyControlsContext } from "../../../hooks/context/survey/surveyControlsContext";

export const useConditionality = (
  node: SurveyDecisionTreeNode,
  moveNext: () => void
) => {
  const { surveyPath } = useSurveyControlsContext();

  const checkNodeConditionalityFromPath = (conditionality: Conditionality) => {
    if (!surveyPath) return;

    const checkConditionalityParam = (target: string, param: Operand) => {
      if (target !== "path") return false;

      for (const path of surveyPath) {
        if (path.includes(param as string)) {
          return true;
        }
      }

      return false;
    };

    if (!checkConditionality(conditionality, checkConditionalityParam)) {
      moveNext();
    }
  };

  useEffect(() => {
    if (node.conditionality) {
      checkNodeConditionalityFromPath(node.conditionality);
    }
  }, [node]);
};

import { UploadStatus } from "@unitelife/cloudflare/types";

export type UploadingMediaProps = {
  name: string;
  uploadProgress: number;
  uploadStatus?: UploadStatus;
  onRemoveClick: () => void;
};

const maxVideoLength = 30;

export const UploadingMedia = ({
  name,
  uploadProgress,
  uploadStatus,
  onRemoveClick,
}: UploadingMediaProps) => {
  return (
    <div className="uploading-media-container">
      <div className="uploading-media">
        <div className="media-header-row">
          <p className="media-name">
            {name.substring(0, maxVideoLength)}
            {name.length > maxVideoLength ? "..." : ""}
          </p>
        </div>
        {uploadStatus === "uploading" && (
          <div className="progress-bar">
            <div
              className="progress"
              style={{ width: `${uploadProgress ?? 0}%` }}
            />
          </div>
        )}
        {uploadStatus === "complete" && (
          <p className="upload-success">Upload complete.</p>
        )}
        {uploadStatus === "error" && (
          <p className="upload-error">
            An unexpected error has occurred, please try again
          </p>
        )}
      </div>
      {uploadStatus === "complete" && (
        <h5 className="remove-upload" onClick={onRemoveClick}>
          Remove
        </h5>
      )}
    </div>
  );
};

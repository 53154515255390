import { useTranslation } from "react-i18next";
import { SurveyDto } from "../../../types";

export type SurveyResultProps = {
  config: SurveyDto;
  resultKey?: string;
};

export const SurveyResult = ({ resultKey, config }: SurveyResultProps) => {
  const { t } = useTranslation();

  const countryCode = config.seeker.user.country;
  const languageCode = config.seeker.user.primary_language;
  let facebookGroupLink = "";
  let discipleshipCourseLink = "";

  if (countryCode === "in") {
    facebookGroupLink = "https://www.facebook.com/groups/689633235244395";
  } else if (countryCode === "ph") {
    facebookGroupLink = "https://www.facebook.com/groups/2875998599369856";
  } else {
    facebookGroupLink = "https://www.facebook.com/groups/733401604682994";
  }

  if (languageCode === "hi") {
    discipleshipCourseLink = "https://www.discipleshipcourses.com/courses/discipleship-foundations-series-step-1-salvation-hindi";
  }
  if (languageCode === "en") {
    discipleshipCourseLink = "https://www.discipleshipcourses.com/courses/discipleship-foundations-series-step-1-salvation-english";
  }

  return (
    <div className="survey-result">
      <p>{t(`survey.result.thanks`)}</p>
      {resultKey && resultKey !== "default" && (
        <p>{t(`survey.result.${resultKey}`)}</p>
      )}
      <a className="survey-facebook-link" href={facebookGroupLink}>
        {t(`survey.result.facebook-group-invite`)}
      </a>
      {discipleshipCourseLink && (
        <a className="survey-course-link" href={discipleshipCourseLink}>
          {t(`survey.result.discipleship-course-invite`)}
        </a>
      )}
    </div>
  );
};

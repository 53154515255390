import { createContext, PropsWithChildren, useContext, useState } from "react";
import { Partner } from "@unitelife/core";
import { Geolocation, useLocation } from "./useLocation";
import {
  PageInteractionHookResult,
  usePageInteractions,
} from "./usePageInteractions";

const interactiontrackingContext =
  createContext<InteractionTrackingContextResult>({} as any);

export const InteractionTrackingContext = (props: PropsWithChildren<{}>) => (
  <interactiontrackingContext.Provider value={useInteractionTracking()}>
    {props.children}
  </interactiontrackingContext.Provider>
);

export const useInteractionTrackingContext = () =>
  useContext(interactiontrackingContext);

export type InteractionTrackingContextResult = {
  partner?: Partner;
  language?: string;
  initializeTracking: (partner: Partner, language: string) => void;
  location?: Geolocation;
  interactions: PageInteractionHookResult;
};

const defaultLanguage = "en";

const useInteractionTracking = (): InteractionTrackingContextResult => {
  const [partner, setPartner] = useState<Partner>();
  const [language, setLanguage] = useState<string>();
  const location = useLocation();
  const interactions = usePageInteractions(location, language, partner);

  const initializeTracking = (partner: Partner, language?: string) => {
    setPartner(partner);
    setLanguage(language ?? defaultLanguage);
  };

  return {
    partner,
    language,
    initializeTracking,
    location,
    interactions,
  };
};

import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type ButtonProps = {
  /** The button text */
  text: string;
  /** The click event handler */
  onClick: () => void;
  /** Whether or not this button should appear 'selected' */
  isSelected?: boolean;
};

const Button = ({ text, onClick, isSelected = false }: ButtonProps) => (
  <button className={`button ${isSelected && "selected"}`} onClick={onClick}>
    {text}
    {isSelected && <FontAwesomeIcon icon={faCheck} />}
  </button>
);
export default Button;

import { createUserMedia } from "@unitelife/cloudflare";
import { ImageUpload } from "./imageUpload";
import { MediaUploadProps } from "./mediaUploadProps";
import { VideoUpload } from "./videoUpload";

export const MediaUpload = ({
  type,
  shouldCreateUserMedia,
  onUploadComplete,
  userMediaProps,
  ...props
}: MediaUploadProps) => {
  const handleUploadComplete = async (id: string, fileName: string) => {
    console.log("cloudflareId:", id);
    if (shouldCreateUserMedia) {
      const result = await createUserMedia({
        user_uuid: userMediaProps!.user_uuid,
        cloudflare_media_id: id,
        media_type: type,
        file_name: props.uploadedName,
      });
      if (result.ok) {
        onUploadComplete({
          success: true,
          mediaCreated: true,
          id,
          media: result.body,
        });
        return;
      }
    }
    onUploadComplete({
      success: true,
      mediaCreated: false,
      id,
    });
  };

  if (type === "image") {
    return <ImageUpload {...props} onUploadComplete={handleUploadComplete} />;
  } else {
    return <VideoUpload {...props} onUploadComplete={handleUploadComplete} />;
  }
};

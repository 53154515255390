import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { StringKeyedObject, SurveyDecisionTreeNode } from "../../../types";
import Button from "../../button";
import { useLocation } from 'react-router-dom';

export type SurveyReviewProps = {
  nodes: SurveyDecisionTreeNode[];
  answers: StringKeyedObject;
  onSubmit: () => void;
  onReset: () => void;
};

type SurveyReviewAnswer = {
  title: string;
  answer: string;
  isTestimony?: boolean;
};

export const SurveyReview = ({
  nodes,
  answers,
  onSubmit,
  onReset,
}: SurveyReviewProps) => {
  const [reviewAnswers, setReviewAnswers] = useState<SurveyReviewAnswer[]>([]);
  const location = useLocation();
  const surveyType = location.pathname.split('/survey/')[1];
  const { t } = useTranslation();

  useEffect(() => {
    if (nodes?.length > 0) {
      setReviewAnswers(
        Object.entries(answers).reduce((res, [questionUuid, answer]) => {
          if (answer) {
            const { question } = nodes.find(
              (x) => x.question.uuid === questionUuid
            )!;
            const item = {
              title: t(`survey.question.${surveyType}.${question.question_key}`),
              answer:
                question.question_type === "TESTIMONY"
                  ? answers[question.uuid]
                  : t(`survey.answer.${answers[question.uuid].toLowerCase()}`),
              isTestimony: question.question_type === "TESTIMONY",
            };
            res.push(item);
          }
          return res;
        }, [] as SurveyReviewAnswer[])
      );
    }
  }, [nodes]);

  return (
    <div className="survey-review">
      <p>{t("survey.review.title")}?</p>
      <div className="answers-container">
        {reviewAnswers.map(({ title, answer, isTestimony }, i) => (
          <div className="survey-review-answer" key={i}>
            <p>{title}?</p>
            <p className={`answer ${isTestimony && "testimony"}`}>{answer}</p>
          </div>
        ))}
      </div>
      <div className="review-button-row">
        <Button text="Reset" onClick={onReset} />
        <Button text="Submit" onClick={onSubmit} />
      </div>
    </div>
  );
};

import "baselayer.css/dist/baselayer-light.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { SurveyControlsContext } from "./hooks/context/survey/surveyControlsContext";
import "./i18n";
import { ErrorPage, HomePage, SurveyPage } from "./pages";
import {
  SurveyContext,
  useSurveyContext,
} from "./hooks/context/survey/surveyContext";
import Spinner from "./components/spinner";
import "./assets/scss/style.scss";
import { InteractionTrackingContext } from "./hooks/context/interactionTracking/interactionTrackingContext";

const SurveyWithContext = () => {
  const { surveyLoaded } = useSurveyContext();

  if (!surveyLoaded) {
    return <Spinner scale={1} />;
  }

  return (
    <>
      <SurveyControlsContext>
        <SurveyPage />
      </SurveyControlsContext>
    </>
  );
};

const App = () => {
  return (
    <Router>
      <Switch>
        <Route path="/survey/:type">
          <InteractionTrackingContext>
            <SurveyContext>
              <SurveyWithContext />
            </SurveyContext>
          </InteractionTrackingContext>
        </Route>
        <Route exact path="/error" component={ErrorPage} />
        <Route exact path="/" component={HomePage} />
      </Switch>
    </Router>
  );
};

export default App;

import React from "react";
import "./spinner.scss";

type SpinnerProps = {
  /** Determines the scale of the spinner  */
  scale: number;
};

const Spinner = ({ scale = 1 }: SpinnerProps) => {
  return (
    <div className="spinner-container" style={{ transform: `scale(${scale})` }}>
      <div className="dot first"></div>
      <div className="dot second"></div>
      <div className="dot third"></div>
      <div className="dot fourth"></div>
      <div className="line vertical"></div>
      <div className="line horizontal"></div>
      <div className="line diagonal"></div>
    </div>
  );
};

export default Spinner;

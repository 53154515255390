import { BrandingPackage } from "./types/partner";

export const generateColorProps = (brandingPackage: BrandingPackage) => {
  let colors = "";
  colors += `--color-primary: ${brandingPackage.primary_color}; `;
  brandingPackage.primary_colors.forEach((color, i) => {
    colors += `--color-primary-${i + 1}: ${color}; `;
  });
  colors += `--color-secondary: ${brandingPackage.secondary_color}; `;
  brandingPackage.secondary_colors.forEach((color, i) => {
    colors += `--color-secondary-${i + 1}: ${color}; `;
  });
  colors += `--color-accent: ${brandingPackage.accent_color}; `;
  brandingPackage.accent_colors.forEach((color, i) => {
    colors += `--color-accent-${i + 1}: ${color}; `;
  });
  const style = `<style id="costom-props" type="text/css">:root{${colors}}</style>`;
  document.head.insertAdjacentHTML("beforeend", style);
  return style;
};

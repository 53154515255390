import { useEffect } from "react";
import { SurveyDto } from "../../../types/survey";

export type SurveyEventsHookResult = {
  registerSurveyStarted: () => Promise<void>;
  registerSurveyCompleted: () => Promise<void>;
};

export const useSurveyEvents = (config: SurveyDto): SurveyEventsHookResult => {
  useEffect(() => {
    registerSurveyStarted();
  }, []);

  const registerSurveyStarted = async () => {
    // return ServerAPI.registerSurveyEvent(seeker!.uuid, type, "start");
  };
  const registerSurveyCompleted = async () => {
    // return ServerAPI.registerSurveyEvent(seeker!.uuid, type, "complete");
  };

  return {
    registerSurveyStarted,
    registerSurveyCompleted,
  };
};

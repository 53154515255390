import { useParams } from "react-router";
import useQuery from "../../useQuery";
import uuidValidate from "uuid-validate";
import { SurveyDto, SurveyType } from "../../../types";

const validSurveyTypes = ["3D", "2W", "2M", "6M", "12M"];

export const useSurveyValidation = (config: SurveyDto): boolean => {
  const { type } = useParams<{ type: SurveyType }>();
  const query = useQuery();

  return (
    validSurveyTypes.includes(type?.toUpperCase()) &&
    !!config.seeker &&
    uuidValidate(query.get("uuid")!)
  );
};

import { useState } from "react";
import { SurveyDecisionTreeNode, SurveyDto } from "../../../types/survey";
import { useSurveyPath, SurveyPathHookResult } from "./useSurveyPath";

export type SurveyNavigationHookResult = {
  currentNode: SurveyDecisionTreeNode;
  moveNext: (answer: string) => void;
  moveBack: () => void;
  showBackButton: boolean;
  surveyCompleted: boolean;
  resetControls: () => void;
} & Partial<SurveyPathHookResult>;

export const useSurveyNavigation = ({
  tree,
}: SurveyDto): SurveyNavigationHookResult => {
  const [currentNode, setCurrentNode] = useState<SurveyDecisionTreeNode>(
    tree.nodes[0]
  );
  const [showBackButton, setShowBackButton] = useState(false);
  const [surveyCompleted, setSurveyCompleted] = useState(false);

  const {
    currentPathIndex,
    resetSurveyPath,
    updateSurveyPath,
    getLastSurveyPath,
    surveyPath,
  } = useSurveyPath();

  const findNodeByPath = (path: string) => {
    return tree.nodes.find((q) => q.path === path);
  };

  const moveNext = (answer: string) => {
    let path = currentNode?.path;
    let newPath = `${path}.${answer.toLowerCase()}`;

    let nextNode = findNodeByPath(newPath);
    let nextTreeIndex = currentPathIndex;
    if (!nextNode) {
      nextTreeIndex++;
      nextNode = findNodeByPath(tree.decision_tree_ordering[nextTreeIndex]);
    }

    updateSurveyPath(newPath, nextTreeIndex !== currentPathIndex);
    setShowBackButton(true);
    if (nextNode) {
      setCurrentNode(nextNode);
    } else {
      setSurveyCompleted(true);
    }
  };

  const moveBack = () => {
    let path = currentNode?.path.includes(".")
      ? currentNode.path
      : getLastSurveyPath();

    let split = path.split(".");
    split.pop();
    let newPath = split.join(".");

    let nextNode = findNodeByPath(newPath);
    if (nextNode) {
      setCurrentNode(nextNode);
    }
    setShowBackButton(nextNode?.path !== tree.nodes[0].path);
  };

  const resetControls = () => {
    setCurrentNode(tree.nodes[0]);
    setShowBackButton(false);
    setSurveyCompleted(false);
    resetSurveyPath();
  };

  return {
    currentNode,
    moveNext,
    moveBack,
    showBackButton,
    surveyCompleted,
    surveyPath,
    resetControls,
  };
};

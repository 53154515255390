import { useImageUpload } from "../../hooks/useImageUpload";
import { useState } from "react";
import { MediaUploadChildProps } from "./mediaUploadProps";
import { DragDropInput } from "../dragDropInput";
import { UploadingMedia } from "../uploadingMedia";

export const ImageUpload = ({
  uploadedName,
  onUploadComplete,
  onRemoveClick,
}: MediaUploadChildProps) => {
  const [imageName, setImageName] = useState<string>();
  const { upload, progress, uploadStatus, reset } = useImageUpload();

  const onFileSelect = async (file: File) => {
    setImageName(file.name);
    const imageId = await upload(file, uploadedName);
    if (imageId) {
      setTimeout(() => {
        onUploadComplete(imageId, file.name);
      }, 1000);
    }
  };

  const onUploadRemove = () => {
    setImageName(undefined);
    reset();
    onRemoveClick();
  };

  return (
    <div className="image-upload">
      {!imageName && (
        <DragDropInput
          type="image"
          accept={{ "image/png": [".png"], "image/jpeg": [".jpeg", ".jpg"] }}
          onFileSelect={onFileSelect}
        />
      )}
      {!!imageName && (
        <UploadingMedia
          name={imageName!}
          uploadStatus={uploadStatus}
          uploadProgress={progress}
          onRemoveClick={onUploadRemove}
        />
      )}
    </div>
  );
};

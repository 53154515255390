import { useTranslation } from "react-i18next";
import Page from "../../components/page";

export const ErrorPage = () => {
  const { t } = useTranslation();
  return (
    <Page title="error.title" className="error-page">
      <p>{t("error.message")}</p>
    </Page>
  );
};

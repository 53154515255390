import { useState } from "react";
import { useVideoUpload } from "../../hooks/useVideoUpload";
import { DragDropInput } from "../dragDropInput/dragDropInput";
import { UploadingMedia } from "../uploadingMedia";
import { MediaUploadChildProps } from "./mediaUploadProps";

export const VideoUpload = ({
  uploadedName,
  onUploadComplete,
  onRemoveClick,
}: MediaUploadChildProps) => {
  const [videoName, setVideoName] = useState<string>();

  const onComplete = (id: string) => {
    return onUploadComplete(id, videoName!);
  };

  const { upload, uploadStatus, progress, reset } = useVideoUpload(onComplete);

  const onFileSelect = async (file: File) => {
    setVideoName(file.name);
    await upload(file, uploadedName);
  };

  const onUploadRemove = () => {
    reset();
    setVideoName(undefined);
    onRemoveClick();
  };

  return (
    <div className="video-upload">
      {!videoName && (
        <DragDropInput
          type="video"
          accept={{
            "video/mp4": [".mp4"],
            "video/mov": [".mov"],
            "video/x-m4v": [".m4v"],
          }}
          onFileSelect={onFileSelect}
        />
      )}
      {!!videoName && (
        <UploadingMedia
          name={videoName}
          uploadProgress={progress}
          uploadStatus={uploadStatus}
          onRemoveClick={onUploadRemove}
        />
      )}
    </div>
  );
};

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { api } from "../../../api";
import { SurveyDto, SurveyType } from "../../../types/survey";
import useQuery from "../../useQuery";

export const useSurveyFetch = () => {
  const [surveyConfig, setSurveyConfig] = useState<SurveyDto>();
  const [surveyLoaded, setSurveyLoaded] = useState(false);

  const query = useQuery();
  const params = useParams<{ type: SurveyType }>();

  const fetchSurvey = async (type: SurveyType, seekerUuid: string) => {
    const result = await api.get<
      { survey_type: SurveyType; seeker_uuid: string },
      SurveyDto
    >("/survey", { survey_type: type, seeker_uuid: seekerUuid });

    if (result.ok) {
      setSurveyConfig(result.body);
    }
    setSurveyLoaded(true);
  };

  useEffect(() => {
    const seekerUuid = query.get("uuid");
    if (params.type && seekerUuid) {
      fetchSurvey(params.type, seekerUuid);
    }
  }, [params]);

  return { surveyConfig, surveyLoaded };
};

import { useScrollPosition } from "../../hooks/useScrollPosition";

export const Background = () => {
  const posBack = useScrollPosition(-0.1);
  const posMid = useScrollPosition(-0.2);
  const posFront = useScrollPosition(-0.3);

  return (
    <div className="background">
      <svg
        className="swoop swoop-accent"
        style={{ transform: `translateY(${posBack}px)` }}
        x="0px"
        y="0px"
        viewBox="0 0 500 500"
      >
        <path
          d="M64.9,69.9c72.9-52.5,169.4,53.1,222,78.5c73.2,35.5,105.9,23.8,150.2,58.8c46.2,36.5,71,102.4,46.8,146.7
c-40.6,74.2-194.5,100.9-309.7,65.7C63.1,385.7-73.2,169.4,64.9,69.9z"
        />
      </svg>
      <svg
        className="swoop swoop-primary"
        style={{ transform: `translateY(${posMid}px)` }}
        x="0px"
        y="0px"
        viewBox="0 0 500 500"
      >
        <path
          d="M69.3,111.6c90-13.6,127.4,16.4,180.9,33.6c57.7,18.6,115.9,25.7,182.5,35.2c80.1,11.5,65.6,160.2,42.7,206.7
	c-38.3,77.8-172.3,108.4-281.5,73.9C48.1,414.9-66.4,132.1,69.3,111.6z"
        />
      </svg>

      <div
        className="grain"
        style={{ transform: `translateY(${posFront}px)` }}
      ></div>
    </div>
  );
};

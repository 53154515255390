import { createContext, useContext, useEffect } from "react";
import { useSurveyFetch } from "./useSurveyFetch";
import { SurveyDto } from "../../../types/survey";
import { useInteractionTrackingContext } from "../interactionTracking/interactionTrackingContext";
import i18next from "i18next";

type SurveyContextType = {
  surveyConfig: SurveyDto;
  surveyLoaded: boolean;
};

const surveyContext = createContext<SurveyContextType>({} as any);
export const SurveyContext = (props: any) => (
  <surveyContext.Provider value={useSurveyContextData()}>
    {props.children}
  </surveyContext.Provider>
);
export const useSurveyContext = () => useContext(surveyContext);

const useSurveyContextData = (): SurveyContextType => {
  const { surveyConfig, surveyLoaded } = useSurveyFetch();
  const { initializeTracking } = useInteractionTrackingContext();

  useEffect(() => {
    if (!!surveyConfig) {
      initializeTracking(
        surveyConfig.partner,
        surveyConfig.seeker.user.primary_language
      );
      i18next.changeLanguage(surveyConfig.seeker.user.primary_language ?? "en");
    }
  }, [surveyConfig]);

  return {
    surveyConfig: surveyConfig!,
    surveyLoaded,
  };
};

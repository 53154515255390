import { useState } from "react";
import { api } from "../../../api";

export type SurveyResultsHookResult = {
  loadingResults: boolean;
  hasSubmitted: boolean;
  resultKey?: string;
  getSurveyResults: (answerTrees: string[]) => void;
};

export const useSurveyResults = (): SurveyResultsHookResult => {
  const [loadingResults, setLoadingResults] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [resultKey, setResultKey] = useState<string>();

  const getSurveyResults = async (answers: string[]) => {
    setLoadingResults(true);
    const result = await api.post<
      { answers: string[] },
      { resultText: string }
    >("/survey/result", { answers });
    if (result.ok) {
      if (result.body) {
        setResultKey(result.body.resultText);
      }
      setHasSubmitted(true);
      setLoadingResults(false);
      // @ts-ignore
      wisepops("event", "show-nota-pop-up");
    }
  };

  return {
    loadingResults,
    hasSubmitted,
    resultKey,
    getSurveyResults,
  };
};

import { useEffect } from "react";
import { useDropzone, Accept } from "react-dropzone";
import { useTranslation } from "react-i18next";

export type DragDropInputProps = {
  type: "video" | "image";
  accept: Accept;
  onFileSelect: (file: File) => void;
};

export const DragDropInput = ({
  type,
  accept,
  onFileSelect,
}: DragDropInputProps) => {
  const { t } = useTranslation();
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    maxFiles: 1,
    accept,
  });

  useEffect(() => {
    if (acceptedFiles.length) {
      onFileSelect(acceptedFiles[0]);
    }
  }, [acceptedFiles]);

  return (
    <div {...getRootProps({ className: "drag-drop-input" })}>
      <input className="input-zone" {...getInputProps()} />
      <p className="dropzone-content">
        {type === "image"
          ? t("uploads.dragDropImage")
          : t("uploads.dragDropVideo")}{" "}
        <span className="dropzone-content-link">
          {type === "image"
            ? t("uploads.selectImage")
            : t("uploads.selectVideo")}
        </span>
      </p>
    </div>
  );
};

import { Redirect } from "react-router";
import {
  SurveyProgressBar,
  SurveyNode,
  SurveyResult,
  SurveyReview,
} from "components/survey";
import { useSurveyControlsContext } from "hooks/context/survey/surveyControlsContext";
import Spinner from "components/spinner";
import Page from "components/page";
import { useSurveyValidation } from "hooks/context/survey/useSurveyValidation";
import { useEffect, useState } from "react";
import { generateColorProps } from "@unitelife/core";

export const SurveyPage = () => {
  const {
    surveyConfig,
    progress,
    currentNode,
    previousResponses,
    onNextClick,
    showBackButton,
    onBackClick,
    surveyCompleted,
    answers,
    onSubmitClick,
    onResetClick,
    hasSubmitted,
    resultKey,
    loadingResults,
  } = useSurveyControlsContext()!;

  const [currentAnswer, setCurrentAnswer] = useState("");

  const questionUuid = currentNode?.question.uuid!;
  const previousResponse = previousResponses?.[questionUuid];
  const seekerIsValid = useSurveyValidation(surveyConfig);

  useEffect(() => {
    generateColorProps(surveyConfig.partner.branding_package);
  }, []);

  const handleNextClick = () => {
    onNextClick(
      currentNode.question.uuid,
      !!currentAnswer ? currentAnswer : previousResponse,
      currentNode.question.question_type === "TESTIMONY"
        ? "testimony"
        : undefined
    );
    setCurrentAnswer("");
  };

  if (!seekerIsValid) {
    return <Redirect to="/error" />;
  }

  return (
    <Page title={`survey.page-title.followUpSurvey`} className="survey-page">
      {loadingResults && (
        <div className="loading">
          <Spinner scale={0.75} />
        </div>
      )}
      {!surveyCompleted && (
        <>
          <SurveyProgressBar progress={progress} />
          <SurveyNode
            node={currentNode}
            answer={currentAnswer}
            previousResponse={previousResponse}
            showBackButton={showBackButton!}
            onAnswerChange={setCurrentAnswer}
            onNextClick={handleNextClick}
            onBackClick={onBackClick}
          />
        </>
      )}
      {surveyCompleted && !hasSubmitted && !loadingResults && (
        <SurveyReview
          nodes={surveyConfig.tree.nodes}
          answers={answers}
          onSubmit={onSubmitClick}
          onReset={onResetClick}
        />
      )}
      {hasSubmitted && (
        <SurveyResult resultKey={resultKey} config={surveyConfig} />
      )}
    </Page>
  );
};

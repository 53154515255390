import { useTranslation } from "react-i18next";
const questionTypes = {
  yesNo: "YES_NO",
  text: "TEXT",
  multipleChoice: "MULTIPLE_CHOICE",
};

const generateOptions = (questionType: string, answerKeys: string[] = []) => {
  switch (questionType) {
    case questionTypes.yesNo:
      return ["YES", "NO"];
    default:
      return answerKeys;
  }
};

export type SurveyAnswersProps = {
  answer?: string;
  previousResponse?: string;
  questionKey: string;
  questionType: string;
  answerKeys?: string[];
  onAnswerClick: (answer: string) => void;
};

export const SurveyAnswers = ({
  answer,
  previousResponse,
  questionKey,
  questionType,
  answerKeys,
  onAnswerClick,
}: SurveyAnswersProps) => {
  const { t } = useTranslation();

  let options = generateOptions(questionType, answerKeys);

  return (
    <div className="survey-answers">
      {options.map((option) => (
        <label key={option} className="survey-answer-row">
          <input
            id={option}
            type="radio"
            name={questionKey}
            checked={
              answer === option || (!answer && previousResponse === option)
            }
            onChange={() => onAnswerClick(option)}
          />
          {t(`survey.answer.${option.toLowerCase()}`)}
          {!answer && previousResponse && previousResponse === option && (
            <span className="previous-response-text">
              {" "}
              {t("survey.answer.fromPrevious")}
            </span>
          )}
        </label>
      ))}
    </div>
  );
};

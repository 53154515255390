import { useEffect, useState } from "react";
import { Geolocation } from "./useLocation";
import { Partner } from "@unitelife/core";
import { rootApi } from "api";

export type PageInteraction = {
  page: "surveys";
  page_url: string;
  coords: [number, number];
  country: string;
  language: string;
  partner: Partner;
  ad_campaign_type?: string;
  ad_campaign_id?: string;
};

export type PageInteractionEvent = {
  page_interaction_uuid: string;
  event_type: "status" | "response";
  event_key: string;
  event_data: any;
};

const LOCAL_STORAGE_PAGE_INTERACTION_UUID_KEY = "surveys.page_interaction_uuid";
const LOCAL_STORAGE_SESSION_TIMESTAMP_KEY = "surveys.last_session_timestamp";

export type PageInteractionHookResult = {
  uuid?: string;
  createEvent: (
    event_type: PageInteractionEvent["event_type"],
    event_key: string,
    event_data: any
  ) => Promise<boolean | undefined>;
};

export const usePageInteractions = (
  location?: Geolocation,
  language?: string,
  partner?: Partner
): PageInteractionHookResult => {
  const [requestLock, setRequestLock] = useState(false);
  const [pageInteractionUuid, setPageInteractionUuid] = useState<string>();

  const checkLastSessionExpired = () => {
    const lastSessionTimestamp = localStorage.getItem(
      LOCAL_STORAGE_SESSION_TIMESTAMP_KEY
    );
    if (!lastSessionTimestamp) {
      return true;
    }
    const lastSessionMillis = new Date(lastSessionTimestamp).getTime();

    return Date.now() - lastSessionMillis >= 1000 * 60 * 60;
  };

  const createPageInteraction = async () => {
    if (location && language && partner && !requestLock) {
      if (!requestLock) {
        setRequestLock(true);
      }

      const existingUuid = localStorage.getItem(
        LOCAL_STORAGE_PAGE_INTERACTION_UUID_KEY
      );

      if (existingUuid && !checkLastSessionExpired()) {
        setPageInteractionUuid(existingUuid);
      } else {
        const interaction: PageInteraction = {
          page: "surveys",
          page_url: window.location.href,
          coords: [location.latitude, location.longitude],
          country: location?.countryCode?.toLowerCase() ?? null,
          language,
          partner,
        };

        const result = await rootApi.post<PageInteraction, { uuid: string }>(
          "/page-interaction",
          interaction
        );
        if (result.ok) {
          setPageInteractionUuid(result.body.uuid);
          localStorage.setItem(
            LOCAL_STORAGE_PAGE_INTERACTION_UUID_KEY,
            result.body.uuid
          );
        } else {
          throw new Error(
            `(${result.statusCode}) Unable to create page interaction: ${interaction}`
          );
        }
      }
      localStorage.setItem(
        LOCAL_STORAGE_SESSION_TIMESTAMP_KEY,
        new Date().toISOString()
      );
    }
  };

  useEffect(() => {
    createPageInteraction();
  }, [location, language, partner, requestLock]);

  const createPageInteractionEvent = async (
    event_type: PageInteractionEvent["event_type"],
    event_key: string,
    event_data: any
  ) => {
    if (!pageInteractionUuid) {
      return;
    }

    const event: PageInteractionEvent = {
      page_interaction_uuid: pageInteractionUuid,
      event_type,
      event_key,
      event_data,
    };

    const result = await rootApi.post<PageInteractionEvent, boolean>(
      "/page-interaction-event",
      event
    );
    if (result.ok) {
      return result.ok;
    } else {
      throw new Error(
        `(${result.statusCode}) Unable to create page interaction event: ${event}`
      );
    }
  };

  return { uuid: pageInteractionUuid, createEvent: createPageInteractionEvent };
};

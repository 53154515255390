import {
  MediaUpload,
  MediaUploadDto,
  CloudflareVideo,
} from "@unitelife/cloudflare";
import { UserMedia } from "@unitelife/core";
import { rootApi } from "api";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

type CreateSurveyUploadDto = {
  userUuid: string;
  mediaUuid: string;
};

type GetSurveyUploadDto = {
  userUuid: string;
};

type DeleteSurveyUploadDto = {
  userUuid: string;
  mediaUuid: string;
};

export type TestimonyUploadProps = {
  seekerUuid: string;
  uploadKey: string;
  type: "profile-photo" | "testimony";
};

export const TestimonyUpload = ({
  seekerUuid,
  uploadKey,
  type,
}: TestimonyUploadProps) => {
  const { t } = useTranslation();
  const [existingUpload, setExistingUpload] = useState<UserMedia>();

  const checkExistingUpload = async () => {
    const result = await rootApi.get<GetSurveyUploadDto, UserMedia | undefined>(
      `uploads/${type}`,
      { userUuid: seekerUuid }
    );
    if (result.ok && result.body) {
      setExistingUpload(result.body);
    }
  };

  const removeUpload = async () => {
    setExistingUpload(undefined);
    await rootApi.delete<DeleteSurveyUploadDto, any>(`uploads/${type}`, {
      userUuid: seekerUuid,
      mediaUuid: existingUpload!.uuid,
    });
  };

  useEffect(() => {
    checkExistingUpload();
  }, []);

  const handleUpload = async (data: MediaUploadDto) => {
    if (data.mediaCreated) {
      await rootApi.post<CreateSurveyUploadDto, any>(`uploads/${type}`, {
        userUuid: seekerUuid,
        mediaUuid: data.media?.uuid,
      });
    }
  };

  return (
    <div className="testimony-upload">
      <div className="upload-title">
        <p>
          {type === "profile-photo"
            ? t("survey.testimony.attachPhoto")
            : t("survey.testimony.attachVideo")}
        </p>
        {!!existingUpload && (
          <h5 className="remove-upload" onClick={removeUpload}>
            {t("survey.testimony.remove")}
          </h5>
        )}
      </div>
      {!!existingUpload && (
        <>
          {type === "profile-photo" ? (
            <div className="profile-photo-container">
              <img
                src={existingUpload.media_url}
                className="profile-photo"
                alt={existingUpload.file_name}
              />
            </div>
          ) : (
            <CloudflareVideo id={existingUpload.cloudflare_media_id} />
          )}
        </>
      )}
      {!existingUpload && (
        <MediaUpload
          type={type === "testimony" ? "video" : "image"}
          shouldCreateUserMedia
          userMediaProps={{ user_uuid: seekerUuid }}
          uploadedName={`${uploadKey
            .toLowerCase()
            .replace(" ", "_")}-${type.replace("-", "")}`}
          onUploadComplete={handleUpload}
          onRemoveClick={removeUpload}
        />
      )}
    </div>
  );
};

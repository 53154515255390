export type Operand = number | string | boolean;

export type Conditionality = {
  operator: "AND" | "OR" | "NOT";
  target: string;
  params: (Operand | Conditionality)[];
};

const checkConditionality = (
  conditionality: Conditionality,
  checkParam: (target: string, param: Operand) => boolean
): boolean => {
  //If we count the number of "true" conditions, we can determine whether or not the conditionality passes
  let numberOfTrueConditions = 0;
  for (let i = 0; i < conditionality.params.length; i++) {
    const param = conditionality.params[i];

    if (typeof param === "object") {
      //If the param is another conditionality, recursively call this function to check it
      if (checkConditionality(param, checkParam)) {
        numberOfTrueConditions++;
      }
    } else {
      //Otherwise, check the param
      if (checkParam(conditionality.target, param)) {
        numberOfTrueConditions++;
      }
    }

    const assertion = checkAssertion(
      conditionality.operator,
      numberOfTrueConditions,
      i + 1
    );
    if (
      //"AND" assertions should always match the number of times through the loop
      (conditionality.operator === "AND" && !assertion) ||
      //"OR" assertions only need one success to return true
      (conditionality.operator === "OR" && assertion) ||
      //"NOT" assertions need to all be unsuccessful
      (conditionality.operator === "NOT" && assertion)
    ) {
      return assertion;
    }
  }
  return checkAssertion(
    conditionality.operator,
    numberOfTrueConditions,
    conditionality.params.length
  );
};

const checkAssertion = (
  operator: Conditionality["operator"],
  numberOfTrueConditions: number,
  expected: number
) => {
  //If we are using the "AND" operator and our number of true conditions doesn't match the number of expected true conditions
  //the "AND" assertion fails, so we return false
  if (operator === "AND") {
    return numberOfTrueConditions === expected;
  }
  //If we are using the "OR" operator and have at least one true condition,
  //the "OR" assertions passes, so we return true
  else if (operator === "OR") {
    return numberOfTrueConditions >= 1;
  }
  //If we are using the "NOT" operator and have no true conditions,
  //the "NOT" assertions passes, so we return true
  else if (operator === "NOT") {
    return numberOfTrueConditions === 0;
  }

  return false;
};

export default checkConditionality;

import { createContext, useContext, useEffect } from "react";
import { useSurveyAnswers, SurveyAnswersHookResult } from "./useSurveyAnswers";
import {
  useSurveyNavigation,
  SurveyNavigationHookResult,
} from "./useSurveyNavigation";
import {
  usePreviousResponses,
  PreviousSurveyResponseHookResult,
} from "./usePreviousResponses";
import {
  useSurveyProgress,
  SurveyProgressHookResult,
} from "./useSurveyProgress";
import { useSurveyEvents, SurveyEventsHookResult } from "./useSurveyEvents";
import { useSurveyResults, SurveyResultsHookResult } from "./useSurveyResults";
import { useSurveyContext } from "./surveyContext";
import { SurveyDto } from "../../../types/survey";
import {
  SurveyControlsHookResult,
  useSurveyControls,
} from "./useSurveyControls";
import { useInteractionTrackingContext } from "../interactionTracking/interactionTrackingContext";

type SurveyControlsContextResult = {
  surveyConfig: SurveyDto;
} & SurveyAnswersHookResult &
  SurveyNavigationHookResult &
  PreviousSurveyResponseHookResult &
  SurveyProgressHookResult &
  SurveyEventsHookResult &
  SurveyResultsHookResult &
  SurveyControlsHookResult;

const surveyContext = createContext<SurveyControlsContextResult>({} as any);
export const SurveyControlsContext = (props: any) => (
  <surveyContext.Provider value={useSurveyControlsContextData()}>
    {props.children}
  </surveyContext.Provider>
);
export const useSurveyControlsContext = () => useContext(surveyContext);

const useSurveyControlsContextData = (): SurveyControlsContextResult => {
  const { surveyConfig } = useSurveyContext();
  const responses = usePreviousResponses(surveyConfig);
  const answers = useSurveyAnswers(surveyConfig!);
  const navigation = useSurveyNavigation(surveyConfig);
  const progress = useSurveyProgress(surveyConfig);
  const events = useSurveyEvents(surveyConfig);
  const results = useSurveyResults();
  const controls = useSurveyControls(
    progress,
    navigation,
    answers,
    events,
    results
  );

  const { interactions } = useInteractionTrackingContext();
  useEffect(() => {
    if (interactions.uuid) {
      interactions.createEvent("status", surveyConfig.survey_type, {
        status: "started",
      });
    }
  }, [interactions.uuid]);

  useEffect(() => {
    if (navigation.surveyCompleted) {
      interactions.createEvent("status", surveyConfig.survey_type, {
        status: "completed",
      });
    }
  }, [navigation.surveyCompleted]);

  return {
    surveyConfig,
    ...answers,
    ...responses,
    ...progress,
    ...navigation,
    ...events,
    ...results,
    ...controls,
  };
};

import { useState, useEffect } from "react";
import { api } from "../../../api";
import { SurveyResponse } from "../../../types";
import { SurveyDto, SurveyType } from "../../../types/survey";

export type PreviousSurveyResponses = {
  [key: string]: string;
};

export type PreviousSurveyResponseHookResult = {
  previousResponses: PreviousSurveyResponses;
};

export const usePreviousResponses = (
  config?: SurveyDto
): PreviousSurveyResponseHookResult => {
  const [previousResponses, setPreviousResponses] =
    useState<PreviousSurveyResponses>({});

  const getPreviousResponses = async () => {
    if (config) {
      let result = await api.get<
        { seeker_uuid: string; survey_type: SurveyType },
        SurveyResponse[]
      >("/response", {
        seeker_uuid: config.seeker.uuid,
        survey_type: config.survey_type,
      });
      if (result.ok) {
        let responses = result.body.reduce(
          (res, { data }) => ({
            ...res,
            [data.question_uuid]: data.response,
          }),
          {} as PreviousSurveyResponses
        );
        setPreviousResponses(responses);
      }
    }
  };

  useEffect(() => {
    getPreviousResponses();
  }, [config]);

  return { previousResponses };
};

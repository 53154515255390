import { useState } from "react";
import { rootApi } from "../../../api";
import { ImageDirectUploadDto, UploadStatus } from "../types";

export const useImageUpload = () => {
  const [uploadStatus, setUploadStatus] = useState<UploadStatus>();
  const [progress, setProgress] = useState(10);
  const fetchUrl = async () => {
    const result = await rootApi.get<any, ImageDirectUploadDto>(
      "/cloudflare/image"
    );
    if (result.ok && result.body.success) {
      return result.body;
    }
  };

  const upload = async (
    file: File,
    name?: string
  ): Promise<string | undefined> => {
    setUploadStatus("uploading");
    const urlResult = await fetchUrl();
    if (urlResult?.success) {
      const formData = new FormData();
      formData.append("file", file, name ?? file.name);
      const result = await fetch(urlResult.result.uploadURL, {
        method: "POST",
        body: formData,
      });
      if (result.ok) {
        setProgress(100);
        return new Promise((resolve) => {
          setTimeout(() => {
            setUploadStatus("complete");
            resolve(urlResult.result.id);
          }, 600);
        });
      }
    }
    setUploadStatus("error");
  };

  const reset = () => {
    setUploadStatus(undefined);
    setProgress(10);
  };

  return { uploadStatus, progress, upload, reset };
};

import * as tus from "tus-js-client";
import { useEffect, useState } from "react";
import { UploadStatus } from "../types";

export const useVideoUpload = (onComplete: (id: string) => void) => {
  const [uploadStatus, setUploadStatus] = useState<UploadStatus>();
  const [progress, setProgress] = useState(0);
  const [videoId, setVideoId] = useState<string>();

  useEffect(() => {
    if (!!videoId && uploadStatus === "complete") {
      onComplete(videoId);
    }
  }, [videoId, uploadStatus]);

  const upload = async (file: File, name?: string) => {
    setUploadStatus("uploading");

    var upload = new tus.Upload(file, {
      endpoint: `${process.env.REACT_APP_VIDEO_UPLOAD_URL}/cloudflare/video`,
      headers: {
        "Upload-Metadata": "maxDurationSeconds NjAw",
      },
      chunkSize: 150 * 1024 * 1024,
      retryDelays: [0, 3000, 5000, 10000, 20000],
      metadata: {
        name: name ?? file.name,
        filename: file.name,
        filetype: file.type,
      },
      onAfterResponse: (req, res) => {
        const rawBody = res.getBody();
        if (rawBody) {
          const body = JSON.parse(rawBody) as { videoId: string };
          if (body.videoId) {
            setVideoId(body.videoId);
          }
        }
      },
      onError: (error) => {
        console.log("Error: " + error);
        setUploadStatus("error");
      },
      onProgress: (bytesUploaded, bytesTotal) => {
        var percentage = (bytesUploaded / bytesTotal) * 100;
        setProgress(percentage);
      },
      onSuccess: () => {
        setUploadStatus("complete");
      },
    });

    // const previousUploads = await upload.findPreviousUploads();
    // if (previousUploads.length) {
    //   upload.resumeFromPreviousUpload(previousUploads[0]);
    // }
    upload.start();
  };

  const reset = () => {
    setVideoId(undefined);
    setUploadStatus(undefined);
    setProgress(0);
  };

  return {
    upload,
    uploadStatus,
    progress,
    reset,
  };
};
